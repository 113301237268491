<template>
  <div class="neue-organisation">
    <CRow>
      <CCol lg="12">
        <CCard>
          <CCardHeader>
            <slot name="header">
              <strong>Neuen Account erstellen</strong>
            </slot>
          </CCardHeader>
          <CCardBody>
            <OrganizationForm
              @submit="neueOrganisationErstellen"
              :processing="verarbeitung"
              btn-text="Jetzt erstellen"
            />
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import axios from 'axios'
import OrganizationForm from "./base/OrganizationForm"

export default {
  name: 'NeueOrganisation',
  components: { OrganizationForm },
  data() {
    return {
      verarbeitung: false
    }
  },
  methods: {
    neueOrganisationErstellen(daten) {
      this.verarbeitung = true
      axios.post('/organization', daten)
        .then(() => {
          this.$router.push({name: 'Organizations'})
          this.$toaster.makeToast('Erfolg!', "<b>Organisation erfolgreich erstellt!</b>", 'success')
        })
        .catch((fehler) => {
          if (fehler.response) {
            this.$toaster.makeToast('Ups!', `<b>${fehler.response.data.code}</b><br>${fehler.response.data.message}`, 'error')
          } else {
            this.$toaster.makeToast('Fehler!', fehler.message, 'error')
          }
        })
        .finally(() => this.verarbeitung = false)
    }
  }
}
</script>

<style scoped>
.neue-organisation {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.card-header {
  background-color: #f8f9fa;
  border-bottom: 1px solid #e9ecef;
}

.card-body {
  padding: 20px;
}

@media (max-width: 768px) {
  .neue-organisation {
    padding: 10px;
  }
}
</style>
